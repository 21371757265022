export interface IProgressBarProps {
  percent: number;
  width: number;
  height: number;
  zeroOffset: number;
}

export const ProgressBar: React.FC<IProgressBarProps> = props => {
  return (
    <svg height={props.height} width="16rem">
      <rect strokeWidth="1" stroke="#efb7b6" width="100%" height={props.height} rx={props.height / 2} fill="#fff" />
      <rect
        width={(((100 - props.zeroOffset) * props.percent) / 100 + props.zeroOffset).toString() + '%'}
        height={props.height}
        rx={props.height / 2}
        fill="#efb7b6"
      />
    </svg>
  );
};
