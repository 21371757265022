import { IComplete } from '../../definitions';
import { Footer } from './Footer';
import { Thanks } from './Thanks';

interface ICompleteProps {
  aerUtland: boolean;
  complete?: IComplete;
  enkaetNamn?: string;
}

export const Complete: React.FC<ICompleteProps> = props => {
  return (
    <div className="bg-white m-auto lg:max-w-3xl mt-10 shadow p-6 lg:p-16">
      <Thanks enkaetNamn={props.enkaetNamn} aerUtland={props.aerUtland} />
      <Footer aerUtland={props.aerUtland} />
      {!!props.complete && (
        <iframe
          title="reco"
          className="hidden lg:flex mt-3 pt-3 w-full h-[520px] border-t border-y-fb-brown-light"
          src={props.complete.url}
        />
      )}
    </div>
  );
};
