import { IEnkaetFraaga, IEnkaetSvarAlternativ, ISvarPaaFraaga } from '../definitions';
import { RadioButton } from './RadioButton';
import { TextInput } from './TextInput';

interface IFraagaProps {
  fraaga: IEnkaetFraaga;
  trim: string;
  goerTillVersal?: boolean;
  doeljFraaga: boolean;
  isFirstFraaga: boolean;
  setSvar: (fraagesvar: ISvarPaaFraaga) => any;
}

const formatText = (props: IFraagaProps) => {
  if (props.doeljFraaga) return '';

  let ans = props.fraaga.text;
  if (props.trim !== '') {
    ans = props.fraaga.text.substr(props.trim.length).trim();
  }
  if (props.goerTillVersal) {
    ans = ans.substr(0, 1).toUpperCase() + ans.substr(1);
  }
  return ans;
};

export const Fraaga: React.FC<IFraagaProps> = props => {
  if (props.doeljFraaga && props.fraaga.fraagetyp === 'flerval') {
    return FraagetypFlerval(props, true);
  } else if (props.fraaga.fraagetyp === 'flerval') {
    return FraagetypFlerval(props, false);
  } else if (props.fraaga.fraagetyp === 'fritext') {
    return FraagetypFritext(props);
  } else if (props.fraaga.fraagetyp === 'bool') {
    return FraagetypJanej(props);
  } else if (props.fraaga.fraagetyp === 'flervalmatrix') {
    return FraagetypFlervalMatrix(props);
  }
  return <div />;
};

const handleOnChange = (props: IFraagaProps, fraageSvar: ISvarPaaFraaga): void => {
  const nextSvar = { ...fraageSvar.svar };
  const newFraageSvar = { ...fraageSvar, svar: nextSvar };
  props.setSvar(newFraageSvar);
};

const FraagetypFlervalMatrix = (props: IFraagaProps) => (
  <div className="flex flex-col">
    {props.fraaga.svarsAlternativ.map((sa, i) => (
      <div className="mt-2" key={`div-${i}-${props.fraaga.processNodId}`}>
        <span
          className="flex items-center cursor-pointer"
          onClick={() => {
            handleOnChange(props, calculateFraageSvar(props.fraaga, sa));
          }}
        >
          <RadioButton
            checked={props.fraaga.valtSvar !== null && sa.svarsId === props.fraaga.valtSvar.svarsId}
            key={`rb-${i}-${props.fraaga.processNodId}`}
            fraagesvar={calculateFraageSvar(props.fraaga, sa)}
            setSvar={props.setSvar}
          />
          <span>{sa.rubrikText}</span>
        </span>
      </div>
    ))}
  </div>
);

const getJaNejRadioButton = (props: IFraagaProps, isJaOption: boolean) => {
  const index: number = isJaOption ? 0 : 1;

  const svarsAlternativ = props.fraaga.svarsAlternativ[index];
  let isChecked = false;
  if (props.fraaga.valtSvar !== null && svarsAlternativ.svarsId === props.fraaga.valtSvar.svarsId) {
    isChecked = true;
  }

  return (
    <RadioButton
      checked={isChecked}
      key={`rb-${index}-${props.fraaga.processNodId}`}
      fraagesvar={calculateFraageSvar(props.fraaga, svarsAlternativ)}
      setSvar={props.setSvar}
    />
  );
};

const FraagetypJanej = (props: IFraagaProps) => (
  <div>
    <h1>{formatText(props)}</h1>
    <div className="flex items-center">
      <span className="flex items-center mr-2">
        <span>Ja</span> {getJaNejRadioButton(props, true)}
      </span>
      <span className="flex items-center">
        <span>Nej</span> {getJaNejRadioButton(props, false)}
      </span>
    </div>
  </div>
);

const calculateFraageSvar = (fraaga: IEnkaetFraaga, svarsAlternativ: IEnkaetSvarAlternativ): ISvarPaaFraaga => {
  const fraagesvar = {} as ISvarPaaFraaga;
  fraagesvar.fraaga = fraaga;

  if (fraaga.fraagetyp === 'fritext') {
    if (fraaga.valtSvar !== null) {
      fraagesvar.svar = fraaga.valtSvar;
      return fraagesvar;
    } else {
      fraagesvar.svar = fraaga.svarsAlternativ[0];
    }
  } else {
    fraagesvar.svar = svarsAlternativ;
  }
  return fraagesvar;
};

const FraagetypFritext = (props: IFraagaProps) => (
  <label className="block relative">
    <span className="text-label text-fb-brown-text font-bold text-label tracking-wider leading-14 uppercase">
      {formatText(props)}
    </span>
    <TextInput
      fraagesvar={calculateFraageSvar(props.fraaga, props.fraaga.svarsAlternativ[0])}
      setSvar={props.setSvar}
    />
  </label>
);

const FraagetypFlerval = (props: IFraagaProps, single: boolean) => (
  <>
    {single ? (
      <>
        {props.fraaga.svarsAlternativ.length === 4 && props.fraaga.text === 'Hur bor du idag?' ? (
          <div className="contents striped-grid">{getRadioButtons(props, true)}</div>
        ) : (
          <>
            {props.fraaga.text ===
              'Hur tycker du att Fastighetsbyråns sätt att genomföra en visning är i förhållande till andra mäklarföretag?' &&
            props.fraaga.svarsAlternativ.length === 8 ? (
              <div className="contents striped-grid">{getRadioButtons(props, false)}</div>
            ) : (
              <>
                <span></span>
                <span className="col-span-2 text-center hidden lg:inline">{props.fraaga.rubrikDaaligt}</span>
                <span className="col-span-3 lg:col-span-1"></span>
                <span></span>
                <span className="col-span-2 text-right hidden lg:inline">{props.fraaga.rubrikBra}</span>
                <span className="col-span-3 lg:col-span-1"></span>
                <div className="contents striped-grid">{getRadioButtons(props)}</div>
              </>
            )}
          </>
        )}
      </>
    ) : (
      <>
        {props.isFirstFraaga && (
          <>
            <span></span>
            <span className="col-span-2 text-center hidden lg:inline">{props.fraaga.rubrikDaaligt}</span>
            <span className="col-span-3 lg:col-span-1"></span>
            <span></span>
            <span className="col-span-2 text-right hidden lg:inline">{props.fraaga.rubrikBra}</span>
            <span className="col-span-3 lg:col-span-1"></span>
          </>
        )}

        <div className="contents striped-grid">{getRadioButtons(props)}</div>
      </>
    )}
  </>
);

const getRadioButtons = (props: IFraagaProps, isFourCols: boolean = false) => {
  const rows: JSX.Element[] = [];

  if (props.isFirstFraaga) {
    if (!isFourCols) {
      rows.push(<span key={`rb-firstspan`}></span>);
    }

    for (let i = 0; i < props.fraaga.svarsAlternativ.length; i++) {
      const svarsAlternativ = props.fraaga.svarsAlternativ[i];
      rows.push(
        <span
          key={`secondspan-${svarsAlternativ.svarsId}-${props.fraaga.processNodId}`}
          className="text-center opacity-0 lg:opacity-100 self-end"
        >
          {i === props.fraaga.svarsAlternativ.length - 1 && !isFourCols && props.fraaga.svarsAlternativ.length < 8
            ? 'Vet ej'
            : svarsAlternativ.rubrikText}
        </span>
      );
    }
  }
  if (!isFourCols) {
    rows.push(
      <div
        key="div-fourcols"
        className={`lg:text-sm  text-lg flex items-center col-span-8 lg:col-span-1  lg:mt-0 lg:pt-0 lg:border-0 ${
          props.isFirstFraaga ? '' : 'border-t border-fb-orange mt-4 pt-4'
        }`}
      >
        <span>{formatText(props)}</span>
      </div>
    );
  }

  for (let i = 0; i < props.fraaga.svarsAlternativ.length; i++) {
    const svarsAlternativ = props.fraaga.svarsAlternativ[i];
    let isChecked = false;

    if (props.fraaga.valtSvar !== null && svarsAlternativ.svarsId === props.fraaga.valtSvar.svarsId) {
      isChecked = true;
    }

    rows.push(
      <div
        key={`div-${svarsAlternativ.svarsId}-${props.fraaga.processNodId}`}
        className={
          'flex lg:justify-center col-span-8 lg:col-span-1 items-center ' +
          (i > 5 && !isFourCols ? 'lg:border-l border-fb-pink' : '')
        }
      >
        <RadioButton
          checked={isChecked}
          key={`rb-${svarsAlternativ.svarsId}-${props.fraaga.processNodId}`}
          fraagesvar={calculateFraageSvar(props.fraaga, svarsAlternativ)}
          setSvar={props.setSvar}
        />
        <span
          className="lg:hidden"
          onClick={() => handleOnChange(props, calculateFraageSvar(props.fraaga, svarsAlternativ))}
        >
          {i === props.fraaga.svarsAlternativ.length - 1 && !isFourCols && props.fraaga.svarsAlternativ.length < 8
            ? 'Vet ej'
            : svarsAlternativ.rubrikText}
          {i === 0 && props.fraaga.svarsAlternativ.length < 8 ? ' - ' + props.fraaga.rubrikDaaligt : ''}
          {i === props.fraaga.svarsAlternativ.length - 2 && props.fraaga.svarsAlternativ.length < 8
            ? ' - ' + props.fraaga.rubrikBra
            : ''}
        </span>
      </div>
    );
  }
  return rows;
};
