import { IEnkaetFraagegrupp, ISvarPaaFraaga } from '../../definitions';
import { FraageContainer } from './FraageContainer';

interface IFraagegruppProps {
  adress: string;
  fraagegrupp: IEnkaetFraagegrupp;
  gruppNumrering: string;
  setSvar: (fraagesvar: ISvarPaaFraaga) => any;
  senastSvar?: ISvarPaaFraaga;
}

const formatText = (props: IFraagegruppProps) => {
  if (props.fraagegrupp.fraagor[0].text.indexOf('{adress}')) {
    props.fraagegrupp.fraagor[0].text = props.fraagegrupp.fraagor[0].text.replace('{adress}', props.adress);
  }

  return (
    props.gruppNumrering.toString() +
    '. ' +
    (props.fraagegrupp.rubrik === '' || props.fraagegrupp.fraagor.length === 1
      ? props.fraagegrupp.fraagor[0].text
      : props.fraagegrupp.rubrik) +
    (props.fraagegrupp.fraagor.length === 1 ? '' : props.fraagegrupp.skiljetecken)
  );
};

export const FraageGrupp: React.FC<IFraagegruppProps> = props => {
  const fraagorKomponent = props.fraagegrupp.fraagor.map((fraaga, i) => (
    <FraageContainer
      fraaga={fraaga}
      key={`fragecont-${fraaga.processNodId}`}
      trim={props.fraagegrupp.trimmaFraagor && props.fraagegrupp.fraagor.length !== 1 ? props.fraagegrupp.rubrik : ''}
      goerTillVersal={props.fraagegrupp.fraagor.length !== 1 ? props.fraagegrupp.goerTillVersal : false}
      doeljFraaga={props.fraagegrupp.rubrik === '' || props.fraagegrupp.fraagor.length === 1}
      setSvar={props.setSvar}
      isFirstFraaga={i === 0}
    />
  ));

  if (props.fraagegrupp.fraagor[0].fraagetyp === 'flervalmatrix') {
    return (
      <div>
        <div>
          <h1>{formatText(props)}</h1>

          <div>{fraagorKomponent}</div>
        </div>
      </div>
    );
  } else if (
    (props.fraagegrupp.rubrik === '' && props.fraagegrupp.fraagor[0].fraagetyp === 'flerval') ||
    (props.fraagegrupp.fraagor[0].fraagetyp === 'flerval' && props.fraagegrupp.fraagor.length === 1)
  ) {
    return (
      <div>
        <div>
          <h1>{formatText(props)}</h1>

          <div>
            {props.fraagegrupp.fraagor[0].svarsAlternativ.length === 4 &&
            props.fraagegrupp.fraagor[0].text === 'Hur bor du idag?' ? (
              <div className="rowcontainer-4cols">{fraagorKomponent}</div>
            ) : (
              <div>
                {props.fraagegrupp.fraagor[0].text ===
                  'Hur tycker du att Fastighetsbyråns sätt att genomföra en visning är i förhållande till andra mäklarföretag?' &&
                props.fraagegrupp.fraagor[0].svarsAlternativ.length === 8 ? (
                  <div className="rowcontainer-8cols">{fraagorKomponent}</div>
                ) : (
                  <div className="rowcontainer">{fraagorKomponent}</div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  } else if (props.fraagegrupp.fraagor[0].fraagetyp === 'bool') {
    return (
      <div>
        <div>
          <h1>{formatText(props)}</h1>

          <div>{fraagorKomponent}</div>
        </div>
      </div>
    );
  } else if (props.fraagegrupp.fraagor[0].fraagetyp === 'flerval') {
    return (
      <div>
        <div>
          <h1>{formatText(props)}</h1>

          <div>
            <div className="rowcontainer">{fraagorKomponent}</div>
          </div>
        </div>
      </div>
    );
  } else if (props.fraagegrupp.fraagor[0].fraagetyp === 'fritext') {
    return (
      <div>
        <div>
          <h1>{formatText(props)}</h1>
          {fraagorKomponent}
        </div>
      </div>
    );
  }
  return <div></div>;
};
