import {
  FraageTyp,
  IComplete,
  IEnkaet,
  IEnkaetFraaga,
  IEnkaetFraagegrupp,
  IEnkaetFraageSvar,
  IEnkaetSvar,
  IEnkaetSvarAlternativ,
} from '../definitions';

export class EnkaetModel implements IEnkaet {
  adress!: string;
  processId!: number;
  processTypId!: number;
  bostadsmarknadId!: number;
  fraageGrupper: IEnkaetFraagegrupp[];
  currentFraageGrupp!: IEnkaetFraagegrupp;
  startFraaga!: IEnkaetFraaga;
  complete!: IComplete;
  fraageDictionary: { [key: number]: IEnkaetFraaga } = {};
  text!: string;

  constructor(jsonEnkaetDO: any) {
    this.fraageGrupper = [];
    this.mapDataToEnkaet(jsonEnkaetDO);
  }

  // Public methods
  isLastGrupp() {
    return this._isLastGrupp(this.currentFraageGrupp);
  }
  aerFraageGruppBesvarad() {
    return this._aerFraageGruppBesvarad();
  }
  calculateTotalSteps() {
    return (
      this._calculateTotalStepsBack(this.currentFraageGrupp) +
      this._calculateTotalStepsForward(this.currentFraageGrupp, true)
    );
  }
  gotoNextFraageGrupp() {
    return this._gotoNextFraageGrupp();
  }
  gotoPreviousFraageGrupp() {
    return this._gotoPreviousFraageGrupp();
  }
  getEnkaetSvar() {
    return this._getEnkaetSvar();
  }
  getCompleteUrl() {
    return this._getCompleteUrl();
  }
  aerUtland() {
    return this.bostadsmarknadId > 0;
  }

  // Private methods
  private _isLastGrupp(fraageGrupp: IEnkaetFraagegrupp): boolean {
    const lastFraaga = fraageGrupp.fraagor.slice(-1)[0];

    if (lastFraaga.valtSvar) {
      return lastFraaga.valtSvar.next === undefined;
    } else {
      return lastFraaga.svarsAlternativ[0].next === undefined;
    }
  }

  private _aerFraageGruppBesvarad(): boolean {
    const fraagor = this.currentFraageGrupp.fraagor;
    for (let i = 0; i < fraagor.length; i++) {
      const fraaga = fraagor[i];
      if (fraaga.valtSvar === null) {
        return false;
      }
    }
    return true;
  }

  private _calculateTotalStepsBack(group: IEnkaetFraagegrupp): number {
    if (group.previousFraageGrupp === null) {
      return group.aerVillkorsgrupp ? 0 : 1;
    }
    return (group.aerVillkorsgrupp ? 0 : 1) + this._calculateTotalStepsBack(group.previousFraageGrupp);
  }

  private _calculateTotalStepsForward(group: IEnkaetFraagegrupp, firstCall: boolean): number {
    if (this._isLastGrupp(group)) {
      return firstCall ? 0 : 1;
    }

    const lastFraaga = group.fraagor.slice(-1)[0];

    const svarsAlt = lastFraaga.valtSvar !== null ? lastFraaga.valtSvar : lastFraaga.svarsAlternativ[0];

    const countThisGroup = firstCall ? 0 : this._aerVillkorsgrupp(group) ? 0 : 1;

    return countThisGroup + this._calculateTotalStepsForward(svarsAlt.next.fraageGrupp, false);
  }

  private _gotoNextFraageGrupp(): IEnkaetFraagegrupp {
    const nextFraaga = this.findNextFraaga();

    nextFraaga.fraageGrupp.previousFraageGrupp = this.currentFraageGrupp;
    this.currentFraageGrupp = nextFraaga.fraageGrupp;

    this.setupSteg(this.currentFraageGrupp);

    return this.currentFraageGrupp;
  }

  private findNextFraaga(): IEnkaetFraaga {
    const lastFraaga = this.currentFraageGrupp.fraagor.slice(-1)[0];
    let nextFraaga: IEnkaetFraaga;

    if (lastFraaga.valtSvar) {
      nextFraaga = lastFraaga.valtSvar.next;
    } else {
      throw new Error('Index out of range');
    }

    return nextFraaga;
  }

  private _aerVillkorsgrupp(group: IEnkaetFraagegrupp): boolean {
    const enkaetFraaga = group.fraagor.slice(-1)[0];

    const ans =
      enkaetFraaga.text === 'Vad är orsaken till att mäklaren hanterade din bostadsaffär dåligt?' ||
      enkaetFraaga.text ===
        'Vilken är den främsta anledningen till att du är nöjd med hur mäklaren hanterade din affär?' ||
      enkaetFraaga.text === 'Vad är orsaken till att du inte skulle rekommendera Fastighetsbyrån?' ||
      enkaetFraaga.text === 'Vad är orsaken till att du anser att mäklaren hanterade din bostadsaffär dåligt?';
    return ans;
  }

  private _gotoPreviousFraageGrupp() {
    const prevFraageGrupp = this.currentFraageGrupp.previousFraageGrupp;
    if (prevFraageGrupp !== null) {
      this.currentFraageGrupp = prevFraageGrupp;
    }
    return prevFraageGrupp;
  }

  private _getEnkaetSvar(): IEnkaetSvar {
    const svarsarray = [] as IEnkaetFraageSvar[];

    let fraaga = this.startFraaga;
    let goOn: boolean = true;

    while (goOn) {
      if (fraaga.valtSvar == null) {
        throw new Error('Response kan inte skapas då svar saknas för fråga: ' + fraaga.text);
      }
      const svar = fraaga.valtSvar as IEnkaetSvarAlternativ;

      let newSvar = {} as IEnkaetFraageSvar;
      newSvar.aktivitetTypId = fraaga.aktivitetTypId;
      newSvar.processId = this.processId;
      newSvar.processTypId = this.processTypId;
      newSvar.processNodId = fraaga.processNodId;
      newSvar.svarsId = svar.svarsId;
      newSvar.svarsText = svar.svarsText ? svar.svarsText : '';
      svarsarray.push(newSvar);
      if (svar.next === undefined) {
        goOn = false;
      } else {
        fraaga = svar.next;
      }
    }

    let enkaetSvar = {} as IEnkaetSvar;
    enkaetSvar.svar = svarsarray;

    return enkaetSvar;
  }

  private _getCompleteUrl(): IComplete {
    return this.complete;
  }

  private constructCompleteUrl(foernamn: string, efternamn: string, recoKontorId: string, mejlAdress: string) {
    let complete = {} as IComplete;
    let url = 'https://review.reco.se/partner/fastighetsbyran/';
    url += recoKontorId + '?firstName=';
    url += foernamn;
    url += '&lastName=';
    url += efternamn + '&email=';
    url += mejlAdress;

    complete.url = url;
    this.complete = complete;
  }

  private mapDataToEnkaet(jsonEnkaetDO: any) {
    this.buildFraageDictionary(jsonEnkaetDO);
    // Bara enkäter för säljare ska visa reco
    if (
      jsonEnkaetDO.text === 'Enkät "Säljare"' &&
      jsonEnkaetDO.recoKontorId !== undefined &&
      jsonEnkaetDO.recoKontorId !== null &&
      jsonEnkaetDO.recoKontorId !== ''
    ) {
      this.constructCompleteUrl(
        jsonEnkaetDO.person.foernamn,
        jsonEnkaetDO.person.efternamn,
        jsonEnkaetDO.recoKontorId,
        jsonEnkaetDO.person.mejladress.text
      );
    }
    this.adress = jsonEnkaetDO.adress;
    this.processId = jsonEnkaetDO.key;
    this.processTypId = jsonEnkaetDO.processTypId;
    this.bostadsmarknadId = jsonEnkaetDO.bostadsmarknadId;
    this.text = jsonEnkaetDO.text;

    for (let i = 0; i < jsonEnkaetDO.fraagor.length; i++) {
      const enkaetFraagaDO = jsonEnkaetDO.fraagor[i];

      const currentGrupp = this.getFraageGrupp(enkaetFraagaDO);
      this.addFraagaToGrupp(enkaetFraagaDO, currentGrupp);
      this.setSvarsalternativ(enkaetFraagaDO);

      if (enkaetFraagaDO.nodRoll === 0) {
        this.setupStartFraaga(enkaetFraagaDO);
      }
    }
    this.setupSteg(this.currentFraageGrupp);
  }

  private buildFraageDictionary(data: any) {
    data.fraagor.forEach((fraaga: any) => {
      const enkaetFraaga = {
        text: fraaga.fraageText,
        fraagetyp: this.getFraageTyp(fraaga.utfallDomaenTyp),
        valtSvar: null,
        processNodId: fraaga.processNodId,
        aktivitetTypId: fraaga.aktivitetTypId,
      } as IEnkaetFraaga;
      this.setupFlervalsrubrik(enkaetFraaga);

      this.fraageDictionary[fraaga.processNodId] = enkaetFraaga;
    });
  }

  private getFraageGrupp(enkaetFraagaDO: any): IEnkaetFraagegrupp {
    let currentGrupp: IEnkaetFraagegrupp | undefined;
    const fraagaGruppDO = enkaetFraagaDO.enkaetFraageGrupp;

    if (fraagaGruppDO !== null) {
      this.fraageGrupper.forEach(grupp => {
        if (grupp.enkaetFraageGruppId === fraagaGruppDO.enkaetFraageGruppId) {
          currentGrupp = grupp;
        }
      });
    }

    if (currentGrupp !== undefined) {
      return currentGrupp;
    } else {
      return this.createNewFraageGrupp(fraagaGruppDO, enkaetFraagaDO.processNodId);
    }
  }

  private createNewFraageGrupp(fraagaGruppDO: any, processNodId: number): IEnkaetFraagegrupp {
    let currentGrupp: IEnkaetFraagegrupp;

    if (fraagaGruppDO === null) {
      currentGrupp = {
        fraagor: [] as IEnkaetFraaga[],
        previousFraageGrupp: null,
        enkaetFraageGruppId: -processNodId,
        rubrik: '',
        stegrubrik: '',
        stegOrdning: -1,
        aerVillkorsgrupp: null,
        skiljetecken: '',
        trimmaFraagor: false,
        goerTillVersal: false,
      };
    } else {
      currentGrupp = {
        fraagor: [] as IEnkaetFraaga[],
        previousFraageGrupp: null,
        enkaetFraageGruppId: fraagaGruppDO.enkaetFraageGruppId,
        rubrik: fraagaGruppDO.rubrik,
        stegrubrik: '',
        stegOrdning: -1,
        aerVillkorsgrupp: null,
        skiljetecken: fraagaGruppDO.skiljetecken,
        trimmaFraagor: fraagaGruppDO.trimmaFraagor,
        goerTillVersal: fraagaGruppDO.goerTillVersal,
      };
    }

    this.fraageGrupper.push(currentGrupp);
    return currentGrupp;
  }

  private addFraagaToGrupp(fraaga: any, group: IEnkaetFraagegrupp) {
    const enkaetFraaga = this.fraageDictionary[fraaga.processNodId];
    enkaetFraaga.fraageGrupp = group;

    if (
      enkaetFraaga.text ===
      'Hur troligt är det att du skulle välja Fastighetsbyrån om det blev aktuellt att sälja din bostad?'
    ) {
      group.fraagor.unshift(enkaetFraaga);
    } else {
      group.fraagor.push(enkaetFraaga);
    }
  }

  private setupSteg(group: IEnkaetFraagegrupp) {
    const enkaetFraaga = group.fraagor.slice(-1)[0];

    if (
      group.previousFraageGrupp !== null &&
      (enkaetFraaga.text === 'Vad är orsaken till att mäklaren hanterade din bostadsaffär dåligt?' ||
        enkaetFraaga.text === 'Vad är orsaken till att du anser att mäklaren hanterade din bostadsaffär dåligt?' ||
        enkaetFraaga.text ===
          'Vilken är den främsta anledningen till att du är nöjd med hur mäklaren hanterade din affär?' ||
        enkaetFraaga.text === 'Vad är orsaken till att du inte skulle rekommendera Fastighetsbyrån?')
    ) {
      group.stegOrdning = group.previousFraageGrupp.stegOrdning;
      group.stegrubrik = group.previousFraageGrupp.stegOrdning.toString() + 'b';
      group.aerVillkorsgrupp = true;
    } else {
      group.stegOrdning = this._calculateTotalStepsBack(group);
      group.stegrubrik = group.stegOrdning.toString();
      group.aerVillkorsgrupp = false;
    }
  }

  private setupFlervalsrubrik(fraaga: IEnkaetFraaga) {
    if (
      fraaga.text ===
        'Fick du tillräckligt med information om hur affären praktiskt skulle gå till från budgivning till tillträde?' ||
      fraaga.text === 'Fick du tillräckligt med information om köparens och säljarens rättigheter och skyldigheter?' ||
      fraaga.text ===
        'Fick du tillräckligt med information om bostaden före köpet (presentation av bostaden, skick etc.)?' ||
      fraaga.text === 'I vilken utsträckning anser du att mäklaren var kunnig i sakfrågor?' ||
      fraaga.text ===
        'I vilken utsträckning anser du att mäklaren var påläst och kunnig om bostaden, föreningen (om bostadsrätt) och området?' ||
      fraaga.text === 'I vilken utsträckning anser du att mäklaren var driven och engagerad i din bostadsaffär?' ||
      fraaga.text ===
        'I vilken utsträckning anser du att mäklaren var tillgänglig, dvs. bra på att återkomma/återkoppla?' ||
      fraaga.text ===
        'I vilken utsträckning anser du att mäklaren var öppen, dvs. ärlig och transparent i sitt sätt att sköta affären?' ||
      fraaga.text === 'I vilken utsträckning anser du att mäklaren lyssnat av dina behov och tagit hänsyn till dem?' ||
      fraaga.text === 'Anser du att mäklaren uppnått de resultat som ni kommit överens om?' ||
      // För att stödja gamla enkäter START
      fraaga.text === 'Anser du att mäklaren lyssnat av dina behov och tagit hänsyn till dem?' ||
      fraaga.text === 'Upplever du att mäklaren var driven och engagerad i din bostadsaffär?' ||
      fraaga.text === 'Upplever du att mäklaren var bra på att återkomma/återkoppla?' ||
      fraaga.text === 'Upplever du mäklaren som kunnig?' ||
      fraaga.text === 'Har mäklaren uppnått de resultat som ni kommit överens om?'
      // För att stödja gamla enkäter SLUT
    ) {
      fraaga.rubrikBra = 'I mycket hög utsträckning';
      fraaga.rubrikDaaligt = 'I mycket låg utsträckning';
    } else if (
      fraaga.text ===
        'Hur troligt är det att du skulle välja Fastighetsbyrån om det blev aktuellt att sälja din bostad?' ||
      fraaga.text === 'Hur troligt är det att du skulle rekommendera Fastighetsbyrån till dina vänner och bekanta?' ||
      // För att stödja gamla enkäter START
      fraaga.text ===
        'Hur troligt är det att du skulle välja Fastighetsbyrån om det skulle bli aktuellt att sälja din bostad?' ||
      fraaga.text ===
        'Hur troligt är det att du skulle välja Fastighetsbyrån (igen) om det skulle bli aktuellt att sälja din bostad?'
      // För att stödja gamla enkäter SLUT
    ) {
      fraaga.rubrikBra = 'Mycket troligt';
      fraaga.rubrikDaaligt = 'Inte alls troligt';
    } else if (fraaga.text === 'Hur bor du idag?') {
      fraaga.rubrikBra = '';
      fraaga.rubrikDaaligt = '';
    } else {
      fraaga.rubrikBra = 'Mycket bra';
      fraaga.rubrikDaaligt = 'Mycket dåligt';
    }
  }

  private getFraageTyp(utfallDomaenTyp: any): FraageTyp {
    switch (utfallDomaenTyp) {
      case 0: // FSD_UTFALL_DOMAEN_ENKAET_SVAR_STD_2
        return 'flerval';
      case 1: // FSD_UTFALL_DOMAEN_ENKAET_SVAR_STD
        return 'flerval';
      case 2: // FSD_UTFALL_DOMAEN_SVAR_JA_NEJ
        return 'bool';
      case 3: // FSD_UTFALL_DOMAEN_INGA_UTFALL
        return 'fritext';
      case 4: // FSD_UTFALL_DOMAEN_ENKAET_SVAR_JMF_VISNING
        return 'flervalmatrix';
      default:
        throw new Error('Not implemented frågetyp');
    }
  }

  private setSvarsalternativ(enkaetFraagaDO: any) {
    const currentEnkaetFraaga = this.fraageDictionary[enkaetFraagaDO.processNodId];
    const enkaetSvarsAlternativDOList: { [key: number]: any } = {};

    currentEnkaetFraaga.svarsAlternativ = [];

    // Sort
    enkaetFraagaDO.svarsAlternativ.forEach((enkaetSvarsAlternativDO: any) => {
      enkaetSvarsAlternativDOList[enkaetSvarsAlternativDO.key] = enkaetSvarsAlternativDO;
    });

    // Build
    for (let svarsId in enkaetSvarsAlternativDOList) {
      if (enkaetSvarsAlternativDOList.hasOwnProperty(svarsId)) {
        const enkaetSvarsAlternativDO = enkaetSvarsAlternativDOList[svarsId];
        const enkaetSvarAlternativ = {} as IEnkaetSvarAlternativ;

        enkaetSvarAlternativ.svarsId = enkaetSvarsAlternativDO.key;
        enkaetSvarAlternativ.rubrikText = enkaetSvarsAlternativDO.text;
        enkaetSvarAlternativ.next = this.fraageDictionary[enkaetSvarsAlternativDO.nextProcessNodId];

        currentEnkaetFraaga.svarsAlternativ.push(enkaetSvarAlternativ);
      }
    }

    // Set default svar
    switch (currentEnkaetFraaga.fraagetyp) {
      case 'flerval':
        break;
      case 'fritext':
        currentEnkaetFraaga.valtSvar = currentEnkaetFraaga.svarsAlternativ[0];
        break;
      case 'bool':
        break;
      case 'flervalmatrix':
        break;
      default:
        throw new Error('Frågetyp unknown');
    }
  }

  private setupStartFraaga(fraaga: any) {
    this.startFraaga = this.fraageDictionary[fraaga.processNodId];
    this.currentFraageGrupp = this.startFraaga.fraageGrupp;
  }
}
