import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Complete } from './components/complete/Complete';
import { Enkaet } from './components/Enkaet';
import { Logo } from './components/logo/Logo';
import { EnkaetDomain } from './enkaetdomain';

function App() {
  return (
    <BrowserRouter>
      <div className="w-full bg-white flex justify-center py-2 items-center px-12 shadow shadow-fb-pink-light">
        <Logo />
      </div>
      <Routes>
        <Route
          path="/testnvitacksida"
          element={<Complete enkaetNamn={EnkaetDomain.AktivitetTypNamn.ENKAET_VISNINGSDELTAGARE} aerUtland={false} />}
        ></Route>
        <Route path="/:enkaetId" element={<Enkaet></Enkaet>}></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
