import facebook from './fb.svg';

interface IFooterProps {
  aerUtland: boolean;
}

export const Footer: React.FC<IFooterProps> = props => {
  return (
    <p>
      Följ gärna Fastighetsbyrån på Facebook för nyheter, tips och inspiration kring bostäder och boende. Ställ gärna
      frågor till oss och dela med dig av dina tankar och erfarenheter kring bostadsbytet.
      <br />
      <span className="mt-2 block">
        <span className="flex items-center">
          <a
            href={
              props.aerUtland
                ? 'https://www.facebook.com/fastighetsbyranutland/'
                : 'http://www.facebook.com/Fastighetsbyran/'
            }
            target="_blank"
            rel="noreferrer"
          >
            <img src={facebook} className="inline mr-2" alt="" width="18" height="18"></img>
            Fastighetsbyrån på Facebook
          </a>
        </span>
      </span>
    </p>
  );
};
