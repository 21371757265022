import { ISvarPaaFraaga } from '../definitions';

const handleOnChange = (event: any, props: ITextInputProps) => {
  const nextSvar = { ...props.fraagesvar.svar, svarsText: event.target.value };
  const newFraageSvar = { ...props.fraagesvar, svar: nextSvar };
  props.setSvar(newFraageSvar);
};

interface ITextInputProps {
  fraagesvar: ISvarPaaFraaga;
  setSvar: (fraageSvar: ISvarPaaFraaga) => any;
}

export const TextInput: React.FC<ITextInputProps> = props => {
  return (
    <textarea
      className="block pt-1 pb-1 outline-none border-b-solid border-b-2 w-full bg-transparent border-fb-pink focus:border-fb-turquoise"
      name="fritext"
      id={'tx-' + props.fraagesvar.fraaga.processNodId + props.fraagesvar.svar.svarsId}
      placeholder="Skriv fritt här..."
      onChange={event => handleOnChange(event, props)}
      value={props.fraagesvar.svar.svarsText}
    />
  );
};
