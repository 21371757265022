import { IEnkaetFraaga, ISvarPaaFraaga } from '../../definitions';
import { Fraaga } from '../Fraaga';

interface IFraageContainerProps {
  fraaga: IEnkaetFraaga;
  trim: string;
  goerTillVersal?: boolean;
  doeljFraaga: boolean;
  isFirstFraaga: boolean;
  setSvar: (fraagesvar: ISvarPaaFraaga) => any;
}

export const FraageContainer: React.FC<IFraageContainerProps> = props => {
  return (
    <Fraaga
      fraaga={props.fraaga}
      key={`fraga-${props.fraaga.processNodId}`}
      trim={props.trim}
      goerTillVersal={props.goerTillVersal}
      doeljFraaga={props.doeljFraaga}
      setSvar={props.setSvar}
      isFirstFraaga={props.isFirstFraaga}
    />
  );
};
