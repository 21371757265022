import { EnkaetDomain } from '../../enkaetdomain';

interface IThanksProps {
  aerUtland: boolean;
  enkaetNamn?: string;
}

const getHeaderString = (enkaetNamn: string | undefined): string => {
  if (enkaetNamn === EnkaetDomain.AktivitetTypNamn.ENKAET_VISNINGSDELTAGARE) {
    return 'Tack för din medverkan!';
  }
  if (enkaetNamn === EnkaetDomain.AktivitetTypNamn.ENKAET_VISNINGSDELTAGARE_UTLAND) {
    return 'Tack för hjälpen!';
  }
  return 'Tack för att du besvarade enkäten!';
};

const getBodyString = (enkaetNamn: string | undefined): string => {
  if (enkaetNamn === EnkaetDomain.AktivitetTypNamn.ENKAET_VISNINGSDELTAGARE_UTLAND) {
    return 'Har du funderingar så hjälper vi dig gärna, tveka inte att kontakta oss.';
  }
  return 'Söker du bostad så hjälper vi dig gärna, tveka inte att kontakta oss.';
};

export const Thanks: React.FC<IThanksProps> = props => {
  return (
    <>
      <h1>{getHeaderString(props.enkaetNamn)}</h1>
      <p>
        Vi på Fastighetsbyrån strävar alltid efter att förbättra våra tjänster och erbjudanden, ditt svar bidrar till
        vår utveckling.
      </p>
      <p>{getBodyString(props.enkaetNamn)}</p>
      <p>
        Vänliga hälsningar
        <br />
        Fastighetsbyrån
        <br />
        <a target="_blank" href="https://www.fastighetsbyran.com/" rel="noreferrer">
          fastighetsbyran.com
        </a>
        <br />
      </p>
    </>
  );
};
