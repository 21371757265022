import { CdnIconType, Loader, RoundButton } from '@decernointernal/fb-webbkomponenter';
import { ProgressBar } from './progressbar/ProgressBar';

export interface INavigationFooterProps {
  sida: string;
  sidor: number;
  progressPercent: number;
  isFirstPage: boolean;
  isLastPage: boolean;
  aerFraageGruppBesvarad: boolean;
  onPreviousPage: () => any;
  onNextPage: () => any;
  onCompleteSave: () => any;
  isFetching: boolean;
  postFailed: boolean | null;
}

const getNextOrSaveButton = (props: INavigationFooterProps) => {
  const buttonText: string = props.isLastPage ? 'SKICKA IN' : 'NÄSTA';

  return (
    <span
      className="flex items-center justify-end"
      onClick={() => {
        if (props.aerFraageGruppBesvarad) {
          if (props.isLastPage) {
            props.onCompleteSave();
          } else {
            props.onNextPage();
          }
        }
      }}
    >
      <span className={'mr-2 buttontext ' + (!props.aerFraageGruppBesvarad ? 'cursor-default' : 'cursor-pointer')}>
        {buttonText}
      </span>
      <RoundButton
        className={
          'border-fb-pink border disabled:border' +
          (props.isLastPage ? ' text-fb-pink bg-fb-pink' : '') +
          (!props.aerFraageGruppBesvarad ? ' text-fb-pink image-fb-pink' : '')
        }
        icon={CdnIconType.PilHoeger}
        iconSize={'24'}
        disabled={!props.aerFraageGruppBesvarad}
      />
    </span>
  );
};

export const NavigationFooter: React.FC<INavigationFooterProps> = props => {
  return (
    <div className="lg:-mx-16 -mx-6 mt-12 pt-6 border-t border-fb-pink">
      <div className="lg:px-16 px-6">
        <div className="flex justify-center mt-4">
          <ProgressBar percent={props.progressPercent} width={277} height={14} zeroOffset={8} />
        </div>
        <div className="text-center mt-1">
          Sida {props.sida} av {props.sidor}
        </div>

        <div className="mt-2 h-2">
          <Loader size="mini-orange" className={props.isFetching ? '' : 'hidden'} />
        </div>

        <div className="flex justify-between mt-4">
          <div>
            {!props.isFirstPage && (
              <span className="flex items-center justify-end" onClick={() => props.onPreviousPage()}>
                <RoundButton className={'border-fb-pink border'} icon={CdnIconType.PilVaenster} iconSize={'24'} />
                <span className="ml-2 cursor-pointer buttontext">TILLBAKA</span>
              </span>
            )}
          </div>
          {getNextOrSaveButton(props)}
        </div>

        {props.postFailed && <div>Något gick fel när enkäten skulle sparas. Vänligen försök igen.</div>}
      </div>
    </div>
  );
};
