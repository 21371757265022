import { ISvarPaaFraaga } from '../definitions';

export interface IRadioButtonProps {
  checked: boolean;
  fraagesvar: ISvarPaaFraaga;
  setSvar: (fraageSvar: ISvarPaaFraaga) => any;
}

const handleOnChange = (event: any, props: IRadioButtonProps) => {
  const nextSvar = { ...props.fraagesvar.svar };
  const newFraageSvar = { ...props.fraagesvar, svar: nextSvar };
  props.setSvar(newFraageSvar);
};

export const RadioButton: React.FC<IRadioButtonProps> = props => {
  return (
    <div className="radioButtons h-9" onClick={event => handleOnChange(event, props)}>
      <input
        type="radio"
        id={'rb-' + props.fraagesvar.fraaga.processNodId + props.fraagesvar.svar.svarsId}
        checked={props.checked}
        onChange={() => {}}
      />
      <label></label>
    </div>
  );
};
