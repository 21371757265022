import { Loader } from '@decernointernal/fb-webbkomponenter';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { IEnkaet, IEnkaetFraagegrupp, IEnkaetSvar, ISvarPaaFraaga } from '../definitions';
import { EnkaetModel } from '../models/EnkaetModel';
import { Complete } from './complete/Complete';
import { FraageGrupp } from './fraagegrupp/FraageGrupp';
import { NavigationFooter } from './NavigationFooter';

export const Enkaet: React.FC = () => {
  const params = useParams();

  const [enkaet, setEnkaet] = useState<EnkaetModel | null>(null);
  const [isFetching, setIsFetching] = useState(true);
  const [getSuccess, setSuccess] = useState<boolean | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [postFailed, setPostFailed] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [currentFraageGrupp, setCurrentFraageGrupp] = useState<IEnkaetFraagegrupp | null>(null);
  const [senastValtSvar, setSenastValtSvar] = useState<ISvarPaaFraaga | undefined>(undefined);

  React.useEffect(() => {
    const error = (error: Error): void => {
      setSuccess(false);
      setErrorMessage(error.message);
    };

    fetch(`/WebAPI/api/enkaet/${pidPrepare(params.enkaetId || '')}`)
      .then(res => res.json())
      .then((response: any) => {
        if (response.exceptionMessage) {
          let error = new Error(response.exceptionMessage);
          throw error;
        }
        if (response.errorMessage) {
          let error = new Error(response.errorMessage);
          throw error;
        }
        const myEnkaet = new EnkaetModel(response);
        setEnkaet(myEnkaet);
        setCurrentFraageGrupp(myEnkaet.currentFraageGrupp);
        setSuccess(true);
        setIsFetching(false);
      })
      .catch(error);
  }, [params.enkaetId]);

  const pidPrepare = (pid: string) => pid.replace(/\//g, 'URL_ENCODED_SLASH');

  const handleFraageSvar = (fraagesvar: ISvarPaaFraaga) => {
    if (enkaet === null) {
      throw new Error('handleSvar ska inte kunna anropas när enkät är null');
    }

    // console.log('enkaet.handleSvar ' + fraagesvar.fraaga.text + ' ' + fraagesvar.svar.svarsId + ' ' + fraagesvar.svar.rubrikText + ' ' + fraagesvar.svar.SvarsText);

    // sätt valt svar som redan ska vara ett nytt objekt

    const fraaga = enkaet.currentFraageGrupp.fraagor.find(el => el.processNodId === fraagesvar.fraaga.processNodId);
    if (fraaga) {
      fraaga.valtSvar = fraagesvar.svar;
    }

    // sätt om state
    setEnkaet(enkaet);
    setSenastValtSvar(fraagesvar);
  };

  const formatPageNumber = () => {
    return enkaet === null ? 'ingen enkät' : enkaet.currentFraageGrupp.stegrubrik;
  };
  const formatProgress = () => {
    if (enkaet === null) {
      throw new Error('Denna ska inte kunna anropas när enkät är null');
    }
    let size = enkaet.calculateTotalSteps();
    let stegraeknare =
      enkaet === null
        ? 1
        : enkaet.currentFraageGrupp.stegOrdning + (enkaet.currentFraageGrupp.aerVillkorsgrupp ? 0.5 : 0);
    let percent = size < 2 ? 100 : (100 * (size / (size - 1)) * (stegraeknare - 1)) / size;
    return percent;
  };

  const handlePreviousPage = () => {
    if (enkaet === null) {
      throw new Error('handlePreviousPage ska inte kunna anropas när enkät är null');
    }
    if (enkaet !== null && (enkaet.currentFraageGrupp.stegOrdning > 1 || enkaet.currentFraageGrupp.aerVillkorsgrupp)) {
      const cuurrentFraagegrupp = enkaet.gotoPreviousFraageGrupp();

      setCurrentFraageGrupp(cuurrentFraagegrupp);
      setEnkaet(enkaet);
      setPostFailed(false);
      window.scrollTo(0, 0);
    }
  };

  const handleNextPage = () => {
    if (enkaet === null) {
      throw new Error('handleNextPage ska inte kunna anropas när enkät är null');
    }
    if (enkaet !== null && !enkaet.isLastGrupp()) {
      const currentFraageGrupp = enkaet.gotoNextFraageGrupp();

      setCurrentFraageGrupp(currentFraageGrupp);
      setEnkaet(enkaet);
      window.scrollTo(0, 0);
    }
  };

  const handleCompleteSave = () => {
    setPostFailed(false);
    setIsFetching(true);

    // ev ta bort browserhistorik tillbaka till enkäten, inte nu för det är bra test att vi ska visa att enkäten är svarad på
    // this.props.history.replace('/Complete');

    if (enkaet === null) {
      throw new Error('Kan inte spara enkät eftersom enkäten inte är satt');
    }
    postEnkaetSvar(enkaet);
  };

  const postEnkaetSvar = (enkaet: IEnkaet) => {
    const svar = (enkaet as IEnkaet).getEnkaetSvar() as IEnkaetSvar;

    fetch(`/WebAPI/api/enkaet/${pidPrepare(params.enkaetId || '')}/svara`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(svar),
    }).then((response: any) => {
      if (response.exceptionMessage) {
        let error = new Error(response.exceptionMessage);
        throw error;
      }
      if (response.message) {
        let error = new Error('Något gick fel när data skulle postas, försök igen senare.');
        throw error;
      }
      setSuccess(true);
      setIsComplete(true);
      setIsFetching(false);
    });
  };

  if (getSuccess === false) {
    return <div>{errorMessage}</div>;
  } else if (enkaet === null || currentFraageGrupp === null) {
    return (
      <div className="flex justify-center">
        <Loader size="large" />
      </div>
    );
  }
  if (
    isComplete &&
    enkaet.currentFraageGrupp.fraagor[0].valtSvar &&
    enkaet !== null &&
    enkaet.currentFraageGrupp.fraagor[0].valtSvar.rubrikText === 'Svar, nej'
  ) {
    return <Complete complete={enkaet.getCompleteUrl()} aerUtland={enkaet.aerUtland()} />;
  } else if (isComplete) {
    return <Complete enkaetNamn={enkaet.text} complete={enkaet.getCompleteUrl()} aerUtland={enkaet.aerUtland()} />;
  }

  return (
    <div className="bg-white m-auto lg:max-w-3xl mt-10 shadow p-6 lg:p-16">
      <FraageGrupp
        adress={enkaet.adress}
        fraagegrupp={currentFraageGrupp}
        gruppNumrering={enkaet.currentFraageGrupp.stegrubrik}
        key={enkaet.currentFraageGrupp.stegrubrik}
        setSvar={(fraagesvar: ISvarPaaFraaga) => handleFraageSvar(fraagesvar)}
        senastSvar={senastValtSvar}
      />
      <NavigationFooter
        sida={formatPageNumber()}
        sidor={enkaet.calculateTotalSteps()}
        progressPercent={formatProgress()}
        onNextPage={() => handleNextPage()}
        onPreviousPage={() => handlePreviousPage()}
        onCompleteSave={() => handleCompleteSave()}
        isFirstPage={
          enkaet.currentFraageGrupp.stegOrdning === 1 && enkaet.currentFraageGrupp.aerVillkorsgrupp === false
        }
        isLastPage={enkaet.isLastGrupp()}
        aerFraageGruppBesvarad={enkaet.aerFraageGruppBesvarad()}
        isFetching={isFetching}
        postFailed={postFailed}
      />
    </div>
  );
};
